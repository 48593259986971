<template>
  <v-card
    class="px-15 px-sm-25 py-15 py-sm-27 mainBg mx-10 rounded-lg login-card"
    max-width="500"
    width="500"
    flat
  >
    <v-form ref="login" v-model="valid">
      <div class="accentColor--text display-1 pb-10 pb-sm-15">
        Welcome to SoltexPay
      </div>
      
      <div>
        <div class="pb-7">
          <span class="secondaryColor--text">Email</span>
        </div>
        <v-text-field
          v-model="loginForm.email"
          background-color="defaultBg"
          flat
          solo
          :rules="emailRules"
        />
      </div>
  
      <div>
        <div class="pb-7">
          <span class="secondaryColor--text">Password</span>
        </div>
        <v-text-field
          v-model="loginForm.password"
          background-color="defaultBg"
          flat
          solo
          type="password"
          :rules="passwordRules"
        />
      </div>
      
      <div class="d-flex justify-end pt-sm-10">
        <v-btn
          class="defaultBg px-10"
          x-large
          depressed
          height="54"
          type="submit"
          @click.prevent="submitForm"
          :loading="isLoading"
        >Login</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    valid: true,
    loginForm: {
      email: null,
      password: null
    },
    emailRules: [],
    passwordRules: [],
  }),
  computed: {
    ...mapState({
      isLoading: state => state.auth.loading,
      loginSuccess: state => state.auth.login,
      role: state => state.auth.role
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/GET_LOGIN',
      dischargeLogin: 'auth/DISCHARGE_LOGIN',
    }),
    async submitForm() {
      await this.validationForm()
      if (this.$refs.login.validate()) {
        this.login(this.loginForm)
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.passwordRules = [
        v => !!v ||  'Password is required'
      ]
    }
  },
  watch: {
    loginSuccess: {
      handler(value) {
        if (value) {
          if (this.role === 'Manager') {
            this.$router.push({ name: 'Dashboard' })
          } else {
            this.$router.push({ name: 'BuyerInvoices' })
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.dischargeLogin(false)
  }
}
</script>
